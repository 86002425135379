import { useEffect } from 'react';

import { AgEvent } from '../useAgAnalytics';

declare global {
	interface Window {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		FS: any;
	}
}

const FS = typeof window !== 'undefined' ? window?.FS : undefined;

export const fullstoryTrackEvent = ({ eventName, properties }: AgEvent) =>
	FS && FS.event(eventName, properties);

export const useFullStoryExcludePage = () => {
	useEffect(() => {
		FS && FS.shutdown();

		return () => {
			//maybe its not necesary because FS.shutdown should be executed every page
			FS && FS.restart();
		};
	}, []);
};
