type SignInProps = {
	email: string;
	password: string;
};

type SignInResponse = {
	token: string;
	type: 'reset' | 'agent';
};

export const signIn = (props: SignInProps): Promise<SignInResponse> => {
	const headers = new Headers();
	headers.append('Content-Type', 'application/x-www-form-urlencoded');

	const body = new URLSearchParams();
	body.append('email', props.email);
	body.append('password', props.password);

	return fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/v1/agent/login`, {
		method: 'POST',
		headers,
		body,
		credentials: 'include'
	}).then(response => {
		if (!response.ok) {
			throw { statusError: response.status };
		}

		return response.json();
	});
};
