import { Code, ConnectError } from '@connectrpc/connect';
import { GetServerSidePropsContext, NextPageContext } from 'next';
import Router from 'next/router';
import { setCookie } from 'nookies';

import { RequestError, RequestStatusError } from '@agentero/service-errors';

import { queryClient } from 'packages/services/QueryCache';
import { signInRoute } from 'routes';

import { getIsomorphicPathname } from '../getIsomorphicPathname';
import { LogLevel, addLog } from '../logger';

export const isomorphicRedirect = (
	ctx: NextPageContext | GetServerSidePropsContext,
	url: string
) => {
	const { res } = ctx ?? {};

	if (res) {
		queryClient.clear(); //TODO: This is done to avoid agent caching in sever if we redirect the clean of _document.tsx is not executed
		res.writeHead(302, { Location: url });
		res.end();
	} else if (typeof window !== 'undefined') {
		if (typeof window !== 'undefined') {
			window.location.href = url;
			return;
		}
		Router.replace(url);
		return;
	}
};

const isConnectError = (error: RequestError | ConnectError): error is ConnectError => {
	return (error as ConnectError).code !== undefined;
};

export const handleNotAuthorizeRequestError = (error: RequestError, ctx: NextPageContext) => {
	const { req } = ctx ?? {};

	const isConnect = isConnectError(error);

	const isConnectUnauthorized =
		isConnect && (error.code === Code.Unauthenticated || error.code === Code.PermissionDenied);

	const isRequestUnauthorized =
		!isConnect &&
		(error.statusError === RequestStatusError.HttpUnauthorized ||
			error.statusError === RequestStatusError.RpcUnauthenticated);

	if (isConnectUnauthorized || isRequestUnauthorized) {
		const redirectUrl = req?.url || window.location.pathname;

		addLog(
			{
				message: 'Agent tried to enter the portal without login into',
				actionDispatched: `Redirected to: ${signInRoute}`,
				...(isConnect ? {} : error.logMetadata),
				pageRoute: getIsomorphicPathname(ctx),
				redirectionAfterLoginTo: redirectUrl
			},
			LogLevel.Info
		);

		setCookie(ctx, 'redirectUrl', redirectUrl, {
			path: '/',
			httpOnly: false
		});
		isomorphicRedirect(ctx, signInRoute);
	}
};
