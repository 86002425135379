import { useFullStoryExcludePage } from 'packages/analytics/services/fullstory-service';
import { Auth } from 'packages/layout';

import { SignInForm } from './signIn/SignInForm';

export const SignIn = () => {
	useFullStoryExcludePage();

	return (
		<Auth>
			<SignInForm />
		</Auth>
	);
};
