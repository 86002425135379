import React, { FC } from 'react';

import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { AlertStatus } from '@agentero/components';

import { AuthBoxAlert } from 'packages/layout/auth/AuthBox';
import { activateRoute } from 'routes';

import { SignInStatus } from './useSignInForm';

type SignInStatusErrorProps = {
	status: SignInStatus;
};

export const SignInStatusError: FC<SignInStatusErrorProps> = ({ status }) => {
	const StatusError = getSignInStatusError[status];

	return (
		<>
			{status !== SignInStatus.NoError && (
				<AuthBoxAlert status={AlertStatus.Error} data-cy-login-error={status}>
					<StatusError />
				</AuthBoxAlert>
			)}
		</>
	);
};
const NoActivatedError = () => {
	const { t } = useTranslation('signIn');

	return (
		<>
			{t('form.errors.status.noActivatedAccount.text')}
			<Link href={activateRoute} passHref>
				{t('form.errors.status.noActivatedAccount.action')}
			</Link>
			.
		</>
	);
};

const WrongUserError = () => {
	const { t } = useTranslation('signIn');

	return <>{t('form.errors.status.invalidUser')}</>;
};

type GetSignInStatusError = {
	[key in SignInStatus]: () => React.ReactElement;
};

const getSignInStatusError: GetSignInStatusError = {
	[SignInStatus.NoError]: () => null,
	[SignInStatus.NoActivated]: NoActivatedError,
	[SignInStatus.WrongUser]: WrongUserError
};
