import { Agent } from '@agentero/models';
import { Role } from '@agentero/models/agent';

import { agentsRoute, dashboardRoute, getContractRoutePath } from 'routes';

export const signInPathRedirection = (agent: Agent): string => {
	if (agent.role === Role.SuperAdmin) {
		return agentsRoute;
	} else if (!agent.isContractAccepted && !agent.isImpersonation) {
		return getContractRoutePath(agent);
	} else {
		return dashboardRoute;
	}
};
