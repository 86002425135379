import { dehydrate } from '@tanstack/react-query';
import { NextPage } from 'next';
import { v4 as uuidv4 } from 'uuid';

import { SignIn } from 'modules/experts/SignIn';
import { queryClient } from 'packages/services/QueryCache';
import { prefetchAgent } from 'packages/services/fetch/back-ag/useAgentResource';
import { signInPathRedirection } from 'packages/utilities/signInPathRedirection';
import { isomorphicRedirect } from 'packages/utilities/withAgentAuth/getInitialPropsActions';

const SignInPage: NextPage<{}> = () => <SignIn />;

SignInPage.getInitialProps = async ctx => {
	const agentUniqueIdentifier = uuidv4();

	try {
		const agent = await prefetchAgent(
			{
				headers: {
					Cookie: ctx.req?.headers?.cookie
				}
			},
			agentUniqueIdentifier
		);
		const redirectionRoute = signInPathRedirection(agent);
		isomorphicRedirect(ctx, redirectionRoute);

		return { dehydratedState: dehydrate(queryClient) };
	} catch (error) {
		//This means no session exist and we need to stay in login page
		return {
			agentUniqueIdentifier
		};
	}
};

export default SignInPage;
